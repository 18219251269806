import React, { useContext } from "react";
import Layout from "../../../components/Layout/layout";
import TaxiVsBusGerman from "../../../components/Blog-Detail/whyTaxi/german-blog";
import SEO from "../../../components/SEO/seo";
const TaxiVsBus = () => {
	return (
		<Layout>
			<SEO
				title="Warum: Taxi vom Hamburger Flughafen ins Stadtzentrum"
				meta="Online Taxi Neumünster to Kiel | Taxi neumünster to Hamburg"
				description="Ein Taxi vom Flughafen in Betracht zu ziehen, ist heutzutage eine der besten Optionen für Pendler."
			/>
			<TaxiVsBusGerman />
		</Layout>
	);
};

export default TaxiVsBus;
